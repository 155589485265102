import { useFetchUser } from "@multicines/services";
import { useProductsStore, useShoppingCartStore } from "@multicines/stores";
import { useGeoStore } from "@multicines/stores";
import { useCataloguesStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { useCallback, useMemo, useState } from "react";

import { addToCartField, defaultConfig } from "./AddToCartButton.helpers";
import { UseAddToCartProps } from "./AddToCartButton.types";
import CONSTANTS from "config/constants";
import useAnalytics from "hooks/analytics/useAnalytics";
import useShippingCost from "hooks/useShippingCost";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { getBenefitProductId } from "utils/common.utils";
import { saveProductPreference } from "utils/product.utils";

const { CONTENT_TYPE, SHOPPING_CART_DEFAULT_NAME } = CONSTANTS.ARTISN;
const { logAddProductToCart, logUpdateProductInCart } = events.shoppingCart;

export const useAddToCart = (props: UseAddToCartProps) => {
  const { form, onFinish, config = defaultConfig, onError } = props;
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const uid = useAuthStore(state => state.uid);
  const { isTalkShop } = useTalkShop();
  const { commonParams } = useAnalytics({
    isSnackWorkflow: true,
    isTalkShop
  });
  const { data: user } = useFetchUser({ isAnonymous, uid });
  const shoppingCartHook = useShoppingCart();
  const shippingCost = useShippingCost();
  const selectedStore = useStoresStore(store => store.selectedStore);
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const temporalBenefit = useShoppingCartStore(store => store.temporalBenefit);
  const selectedCatalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const selectedCoordinates = useGeoStore(state => state.selectedCoordinates);
  const selectedProduct = useProductsStore(store => store.selectedProduct);
  const setSelectedProduct = useProductsStore(
    store => store.setSelectedProduct
  );
  const setSnackRestrictionCard = useProductsStore(
    store => store.setSnackRestrictionCard
  );
  const [isAdding, setIsAdding] = useState(false);

  const [, snacksStore] = selectedStore ?? [];

  const { amount, comment } = config;

  const { uid: userUid } = user ?? {};
  const { getShoppingCart, createShoppingCart, addProduct } = shoppingCartHook;
  const { replaceProduct } = shoppingCartHook;

  const shouldReplace = !!selectedProduct;
  const { product, validate } = form ?? {};
  const { lat, lng } = selectedCoordinates ?? {};

  const onClick = useCallback(async () => {
    setIsAdding(true);

    if (!createShoppingCart || !getShoppingCart || !addProduct) {
      setIsAdding(false);
      throw new Error("No shopping cart functions");
    }

    if (!snacksStore) {
      setIsAdding(false);
      throw new Error("No snacks store");
    }

    if (!product || !validate) {
      setIsAdding(false);
      throw new Error("No product or validate function");
    }

    const valid = validate() === "OK";
    if (!valid) {
      setIsAdding(false);
      onError?.();
      return;
    }

    if (shouldReplace) {
      const { id, name, benefits } =
        (await getShoppingCart?.({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME
        })) ?? {};

      const selectedBenefit = benefits ? benefits[0] : undefined;
      const benefitProductId = getBenefitProductId(selectedBenefit);
      /* If the product being modified is not equal to the benefitId in the
        cart, replace it and continue normally. If not, do nothing. */
      if (benefitProductId !== product.productId) {
        await replaceProduct?.(product, {
          amount,
          comment,
          store: snacksStore,
          shoppingCartName: name
        });
        saveProductPreference(form, userUid);
        setSelectedProduct(undefined);

        if (snacksStore && id && name) {
          logUpdateProductInCart({
            cartId: id,
            cartName: name,
            product,
            // @ts-ignore Now the vendor id is a string
            store: snacksStore,
            fields: addToCartField,
            contentType: CONTENT_TYPE,
            ...commonParams
          });
        }
      }
    } else {
      if (!shoppingCart) {
        await createShoppingCart(
          {},
          {
            channelId: +selectedCatalogueId,
            shippingCost,
            latitude: lat ?? 0,
            longitude: lng ?? 0,
            name: SHOPPING_CART_DEFAULT_NAME
          }
        );
      }

      const { id, name } =
        (await getShoppingCart({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME
        })) ?? {};

      if (!id) {
        setIsAdding(false);
        throw new Error("No shopping cart id");
      }

      /* If a benefit of type PRODUCT exists in context, prevent addProduct
        from firing up. If this condition is removed, a benefit product is added
        first and another product is added to the cart too, removing the
        benefitId key from the product and breaking the coupons functionality.
      */
      if (!temporalBenefit) {
        await addProduct(product, {
          amount,
          store: snacksStore,
          comment,
          shoppingCartName: name
        });
        await saveProductPreference(form, userUid);
      }

      /* The add product to cart event is kept for coupons because in the
      background, the applyBenefit function runs addProduct too. */
      if (id && name) {
        logAddProductToCart({
          cartId: id,
          cartName: name,
          product,
          store: snacksStore,
          contentType: CONTENT_TYPE,
          ...commonParams
        });
      }
    }

    const { additionalInfo } = product ?? {};
    const { specialTicketType } = additionalInfo ?? {};

    if (specialTicketType) {
      setSnackRestrictionCard(specialTicketType);
    }

    await onFinish?.();

    setIsAdding(false);
  }, [
    addProduct,
    amount,
    comment,
    commonParams,
    createShoppingCart,
    form,
    getShoppingCart,
    lat,
    lng,
    onError,
    onFinish,
    product,
    replaceProduct,
    selectedCatalogueId,
    setSelectedProduct,
    setSnackRestrictionCard,
    shippingCost,
    shoppingCart,
    shouldReplace,
    snacksStore,
    temporalBenefit,
    userUid,
    validate
  ]);

  return useMemo(() => {
    return { shouldReplace, onClick, isAdding };
  }, [isAdding, onClick, shouldReplace]);
};
