import { useShoppingCartStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { CartProduct, Product } from "artisn/types";
import React, { useState } from "react";

import Styles from "./UpsaleModal.styles";
import { UpsaleModalProps as Props } from "./UpsaleModal.types";
import ShoppingCartProduct from "../../Cart/ShoppingCartProduct/ShoppingCartProduct";
import Button from "components/global/Button/Button";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import { useStoresStore } from "stores/stores/stores.store";

const { logRemoveProductFromCart } = events.shoppingCart;

const UpsaleModal: React.FC<Props> = props => {
  const { opened, products, onClose, onSuccess } = props;
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const { removeProduct, setProduct } = useShoppingCart();
  const [count, setCount] = useState(0);

  const { name: shoppingCartName, id: shoppingCartId } = shoppingCart ?? {};
  const [, snacksStore] = selectedStore ?? [];

  const deleteProductHandler = async (product: CartProduct) => {
    if (!snacksStore) {
      console.error("UpsaleModal: snacksStore is undefined");
      return;
    }
    // TODO: SDK
    await removeProduct?.(product, { store: snacksStore, shoppingCartName });

    if (!shoppingCartId || !snacksStore) return;

    logRemoveProductFromCart({
      cartId: shoppingCartId,
      product,
      // @ts-ignore Now the vendor id is a string
      store: snacksStore
    });
  };

  const changeHandler = (amount: number, product: Product) => {
    if (!amount) {
      deleteProductHandler(product as CartProduct);
      return;
    }
    if (!snacksStore) {
      console.error("UpsaleModal: snacksStore is undefined");
      return;
    }

    // TODO: SDK
    setProduct?.(product, {
      amount,
      store: snacksStore
    });
  };

  const headerNode = (
    <div className="UpsaleModal__header">
      <p className="UpsaleModal__title">Esto podria gustarte</p>
      <p className="UpsaleModal__description">
        Personas que compraron productos que aparecen en tu carrito también
        compraron
      </p>
    </div>
  );

  return (
    <Styles
      className="UpsaleModal"
      opened={opened}
      header={headerNode}
      onClose={onClose}
      closeOnClickOutside={false}
    >
      <div className="UpsaleModal__products">
        {products.map(product => {
          const { productId } = product;
          return (
            <ShoppingCartProduct
              key={productId}
              product={product}
              onChangeQuantity={amount => changeHandler(amount, product)}
              onSubtract={() => setCount(prev => prev - 1)}
              onAdd={() => setCount(prev => prev + 1)}
              minCounter={0}
            />
          );
        })}
      </div>
      <div className="UpsaleModal__actions">
        {count ? (
          <Button className="UpsaleModal__button" onClick={onSuccess}>
            Continuar
          </Button>
        ) : (
          <Button
            className="UpsaleModal__button"
            onClick={() => {
              onClose();
              onSuccess();
            }}
          >
            No gracias
          </Button>
        )}
      </div>
    </Styles>
  );
};

UpsaleModal.defaultProps = {};

export default UpsaleModal;
